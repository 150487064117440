<template>
  <app-module-view>
    <template slot="body">
      <app-published-report-filter ref="publishedReportFilter" @ready="submitFilter">
        <template v-slot:buttons>
          <div class="btn btn-info" @click="submitFilter">{{ $t('video.filter.search') }}</div>
          <div class="btn btn-info" @click="unparseDataToCsv">{{ $t('video.csvExport') }}</div>
        </template>
      </app-published-report-filter>
      <div class="card">
        <div class="card-body">
          <div>
            <div class="table-responsive">
              <table id="list-table" class="table table-striped">
                <thead>
                <tr>
                  <th width="20" class="text-left">
                    {{ $t("videoStats.list.department") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.list.socialNetworks") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.list.agencies") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.list.mobilePhone") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.list.slideShow") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.header.onRequest") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.header.shows") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.header.totalVideos") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.header.youtube") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.header.publishedArticlesCount") }}
                  </th>
                  <th width="20" class="text-center">
                    {{ $t("videoStats.header.videosArticlesRate") }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(publishedReportRow, index) in publishedReportData" :key="index">
                  <td>
                    <div class="text-left">
                                                <span>
                                                    {{ publishedReportRow.department }}
                                                </span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                                                <span>
                                                    {{ publishedReportRow.socialNetworks | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                                                <span>
                                                    {{ publishedReportRow.agencies | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                                                <span>
                                                    {{ publishedReportRow.mobilePhone | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                                                <span>
                                                    {{ publishedReportRow.slideShow | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td class="highlight2">
                    <div class="text-center">
                                                <span>
                                                    {{ publishedReportRow.onRequest | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td class="highlight2">
                    <div class="text-center">
                                                <span>
                                                    {{ publishedReportRow.shows | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td class="highlight3">
                    <div class="text-center">
                                                <span>
                                                    {{ publishedReportRow.videosCount | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td class="highlight4">
                    <div class="text-center">
                                                <span>
                                                    {{ publishedReportRow.youtubeCount | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                                                <span>
                                                    {{ publishedReportRow.publishedArticlesCount | formatNumber }}
                                                </span>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                                                <span>
                                                    {{ Math.round(publishedReportRow.videosArticlesRate * 100) }} %
                                                </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import CoreApi from '../../api/core'
import ModuleView from '../../components/ModuleView'
import moment from 'moment'
import PublishedReportFilter from '../../components/video/videoStats/PublishedReportFilter'

export default {
  name: 'PublishedReport',
  components: {
    appModuleView: ModuleView,
    appPublishedReportFilter: PublishedReportFilter
  },
  data () {
    return {
      dataLoaded: false,
      publishedReportData: {},
      filter: {
        dateFrom: null,
        dateTo: null
      }
    }
  },
  methods: {
    submitFilter () {
      this.filter = this.$refs.publishedReportFilter.filter
      this.fetchData()
    },
    fetchData () {
      this.dataLoaded = false
      this.publishedReportData = {}

      const url = '/videostats/published-report?' +
        'fromDate=' + moment(this.filter.dateFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z' +
        '&toDate=' + moment(this.filter.dateTo).add(23, 'hours').add(-1, 's').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'

      CoreApi().get(url)
        .then(response => {
          this.publishedReportData = response.data.reports
          this.dataLoaded = true
        })
        .catch(error => {
          console.log(error)
        })
    },
    unparseDataToCsv () {
      const rows = this.publishedReportData.map(
        publishedReportRow => [
          publishedReportRow.department,
          publishedReportRow.socialNetworks,
          publishedReportRow.agencies,
          publishedReportRow.mobilePhone,
          publishedReportRow.slideShow,
          publishedReportRow.onRequest,
          publishedReportRow.shows,
          publishedReportRow.videosCount,
          publishedReportRow.youtubeCount
        ]
      )

      rows.unshift([
        this.$i18n.t('videoStats.list.department'),
        this.$i18n.t('videoStats.list.socialNetworks'),
        this.$i18n.t('videoStats.list.agencies'),
        this.$i18n.t('videoStats.list.mobilePhone'),
        this.$i18n.t('videoStats.list.slideShow'),
        this.$i18n.t('videoStats.header.onRequest'),
        this.$i18n.t('videoStats.header.shows'),
        this.$i18n.t('videoStats.header.totalVideos'),
        this.$i18n.t('videoStats.header.youtube')
      ])

      const csv = rows.map(row => row.join(';')).join('\n')

      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      let csvURL = null
      const fileName = 'published-report.csv'

      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, fileName)
      } else {
        csvURL = window.URL.createObjectURL(csvData)
      }
      const tempLink = document.createElement('a')
      tempLink.href = csvURL
      tempLink.setAttribute('download', fileName)
      tempLink.click()
    }
  }
}
</script>

<style scoped>
.highlight2 {
  background-color: #eeeece;
}

.highlight3 {
  background-color: #eeaeae;
}

.highlight4 {
  background-color: #bae2ff;
}
</style>
